import React, { useEffect, useState } from "react";
import "./App.css";
import { config } from "./config";
import { Item } from "./Item";
import { InventoryTable } from "./InventoryTable";
import { GetUserData } from "./GetUserData";
import { Lineup } from "./Lineup";
import { MRT_RowSelectionState } from "mantine-react-table";
import { useCookies } from "react-cookie";
import { useParams, useSearchParams } from "react-router-dom";
import { Flex, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { DEFAULT_NUM_COLS, DEFAULT_NUM_ROWS, MAX_COLS, MAX_ROWS, USER_GUIDE_URL } from "./constants";
import { getRecentUsers, updateRecentUsers } from "./recentUsers";
import { parseInput } from "./parsing";
import { InvalidInputError } from "./InvalidInputError";
import { decodeLineup, encodeLineup } from "./user";
import { Disclaimer } from "./Disclaimer";

interface CollectiblesToolProps {
    fromCookie?: boolean;
}

// Main entry point for app (not including mantine / react-router setup).
// Contains functions for loading inventories, updating state, and updating window location to canonical urls.
export function CollectiblesTool(props: CollectiblesToolProps) {
    const params = useParams();
    const [searchParams] = useSearchParams();

    const [cookies, setCookie, removeCookie] = useCookies();
    const [userId, setUserId] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [items, setItems] = useState<Item[]>([]);
    const [itemMap, setItemMap] = useState<{ [id: string]: Item }>({}); // maps item id -> item for fast lookup by id
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true); // don't update window location until after first load() call
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode | undefined>(undefined);
    const [numRows, setNumRows] = useState<number>(DEFAULT_NUM_ROWS);
    const [numCols, setNumCols] = useState<number>(DEFAULT_NUM_COLS);
    const [canonicalUrl, setCanonicalUrl] = useState<string>("");

    useEffect(() => {
        console.log("loading...");
        load();
        setIsInitialLoad(false);
    }, [params.user, params.lineup, searchParams.get("layout")]);

    useEffect(() => {
        if (isInitialLoad || isLoading) {
            // avoid issue where data isn't loaded yet,
            // which causes url to be set incorrectly multiple times for permalinks
            // as inventory is fetched and rowSelection is loaded from encoded lineup
            return;
        }
        updateCanonicalUrl();
    }, [username, userId, rowSelection, numRows, numCols]); // anything that goes in url or url params must be here

    // Use setItemState() to keep both items array and itemMap in sync.
    // Components should use this instead of calling setItems() or setItemMap() directly.
    function setItemState(items: Item[]) {
        if (!items) {
            items = [];
        }
        setItems(items);
        const itemMap = Object.fromEntries(items.map((item) => [item.id, item]));
        setItemMap(itemMap);
    }

    function load() {
        // intentionaly update layout from url parameters independent of an inventory fetch
        updateLayout(searchParams.get("layout"));

        if (props.fromCookie) {
            // page loaded with no dynamic segments
            // try latest cookie format
            const recentUsers = getRecentUsers(cookies);
            if (recentUsers.length > 0) {
                console.log("loading from recent_users cookie...");
                fetchInventory(recentUsers[0].userId);
            } else {
                // try old cookie
                console.log("loading from profile_urls cookie...");
                const profileUrls = cookies["profile_urls"];
                if (profileUrls && Array.isArray(profileUrls) && profileUrls.length > 0) {
                    fetchInventory(profileUrls[0]);
                }
            }
        } else if (params.user) {
            console.log(`loading for user ${params.user}`);
            fetchInventory(params.user, params.lineup);
        }
    }

    /**
     * @param input             user url or user ID to fetch inventory from
     * @param encodedLineup     encoded lineup to preload, if present
     */
    function fetchInventory(input: string, encodedLineup?: string) {
        setErrorMessage(undefined);

        const parsedUser = parseInput(input);
        if (!parsedUser) {
            setErrorMessage(<InvalidInputError />);
            return;
        }

        // proactively set values but this might be overwritten after response if fetched
        // e.g. if user only provides user id or provides wrong username
        setUsername(parsedUser.username ?? "");
        setUserId(parsedUser.userId);

        setIsLoading(true);
        const url = `${config.tbtApiInventoryEndpoint}?userId=${parsedUser.userId}`;
        console.log(`fetching url ${url}`);
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    const errorMessage = (
                        <div>
                            <Flex align="center">
                                <IconAlertCircle color="red" size="1em" />
                                <Text ml={4}>Unable to load inventory.</Text>
                            </Flex>
                            <div>Is the profile viewable by everyone?</div>
                            <div>
                                For more help, see the <a href={USER_GUIDE_URL}>user guide</a>.
                            </div>
                        </div>
                    );
                    setErrorMessage(errorMessage);
                    throw new Error("Unable to load inventory.");
                }
                return response;
            })
            .then((response) => response.json())
            .then((response) => {
                updateRecentUsers(
                    {
                        userId: response.userId,
                        username: response.username,
                    },
                    cookies,
                    setCookie
                );
                deselectAllItems();
                setUserId(response.userId);
                setUsername(response.username);
                setItemState(response.items);
                setLineup(encodedLineup);
                setIsLoading(false);

                // todo uncomment to delete old cookie
                // removeCookie("profile_urls");
            })
            .catch((e: Error) => {
                // Thrower is responsible for setting error message.
                // To avoid that, I need to pass jsx in the error object.
                console.log(`Error fetching inventory: ${e}`);
                deselectAllItems();
                setItemState([]);
                setIsLoading(false);
            });
    }

    // set selected rows to empty object to clear out all previously selected rows
    function deselectAllItems() {
        setRowSelection((prev) => ({}));
    }

    /**
     * Set the row selection to match encodedLineup
     *
     * @param encodedLineup lineup encoded using {@link encodeLineup}
     */
    function setLineup(encodedLineup: string | undefined) {
        if (!encodedLineup) {
            return;
        }

        try {
            const lineup = decodeLineup(encodedLineup);
            // lineup might include item ids the user doesn't have.
            // Maybe they traded it away, or maybe the user just gave bad input.
            // We'll set it anyways and leave it to other components to handle mismatches in rowSelection vs itemMap.
            const nextRowSelection = Object.fromEntries(lineup.map((itemId) => [itemId, true]));
            setRowSelection((prev) => nextRowSelection);
        } catch (e: any) {
            setErrorMessage("failed to load lineup");
        }
    }

    /**
     * Sets layout with error handling and (good enough) validation
     * @param layout url parameter value, expected format is `${rows}x${cols}`
     */
    function updateLayout(layout: string | null) {
        try {
            if (layout) {
                const parts = layout.split("x");
                const rows = parseInt(parts[0], 10);
                const cols = parseInt(parts[1], 10);
                if (rows && 1 <= rows && rows <= MAX_ROWS && cols && 1 <= cols && cols <= MAX_COLS) {
                    // yeah, yeah, if you've got more than 2 parts we'll still handle it
                    updateRowsAndCols(rows, cols);
                    return;
                }
            }
        } catch (e: any) {
            // swallow anything that might go wrong like not enough parts
            // lazy way of handling layout being undefined and any other errors
        }
        // default fallback
        updateRowsAndCols(DEFAULT_NUM_ROWS, DEFAULT_NUM_COLS);
    }

    // sets layout, assumes error handling and validation has been done prior to calling this
    function updateRowsAndCols(rows: number, cols: number) {
        setNumRows(rows);
        setNumCols(cols);
    }

    /**
     * Set canonicalUrl and update the window location.
     * Does not update history, so browser history / back button will not have a new entry each change.
     */
    function updateCanonicalUrl() {
        let canonincalPath = "/";
        if (username && userId) {
            const userStr = `${username}.${userId}`;
            canonincalPath = `/inventory/${encodeURIComponent(userStr)}/`;

            const lineup = encodeLineup(itemMap, rowSelection, numRows, numCols);
            if (lineup) {
                canonincalPath += `${lineup}/`
            }
        }

        if (numRows !== DEFAULT_NUM_ROWS || numCols !== DEFAULT_NUM_COLS) {
            const layout = `${numRows}x${numCols}`;
            canonincalPath += `?layout=${layout}`;
        }

        if (canonincalPath) {
            // todo only do this if it doesn't match current window location
            const canonicalUrl = config.host + canonincalPath;
            console.log(`updating location to canonical url ${canonincalPath}`);
            setCanonicalUrl(canonicalUrl);
            window.history.replaceState(null, "", canonincalPath);
        }
    }

    return (
        <>
            <GetUserData
                username={username}
                userId={userId}
                isLoading={isLoading}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                fetchInventory={fetchInventory}
            />
            <Lineup
                numRows={numRows}
                setNumRows={setNumRows}
                numCols={numCols}
                setNumCols={setNumCols}
                itemMap={itemMap}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                canonicalUrl={canonicalUrl}
                isLoading={isLoading}
            />
            <InventoryTable
                items={items}
                itemMap={itemMap}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                isLoading={isLoading}
            />
            <Disclaimer />
        </>
    );
}
