import { Button, CopyButton } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";

interface CopyPermalinkButtonProps {
    canonicalUrl: string;
}

export function CopyPermalinkButton(props: CopyPermalinkButtonProps) {
    return (
        <CopyButton value={props.canonicalUrl} timeout={2000}>
            {
                ({ copied, copy }) => (
                    <Button
                        size="xs"
                        leftIcon={copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                        color={copied ? 'teal' : 'blue'}
                        onClick={copy}
                    >
                        {copied ? 'Copied!' : 'Copy permalink'}
                    </Button>
                )
            }
        </CopyButton>
    );
}