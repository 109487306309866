import { Alert, Button, Card, Container, Grid, Popover, TextInput } from "@mantine/core";
import React, { useState } from "react";

export interface GetUserDataProps {
    username: string;
    userId: string;
    isLoading: boolean;
    errorMessage?: React.ReactNode;
    setErrorMessage: (errorMessage?: React.ReactNode) => void;
    fetchInventory: (input: string) => void;
}

export function GetUserData(props: GetUserDataProps) {
    const [inputValue, setInputValue] = useState<string>(props.userId ?? "");

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        props.fetchInventory(inputValue);
    }

    return (
        <Container size="xl" px="xs">
            <Card shadow="sm" padding="md" m="md" radius="md" withBorder>
                <form onSubmit={handleSubmit}>
                    <Grid align="flex-end">
                        <Grid.Col span="auto">
                            <SourceInput
                                errorMessage={props.errorMessage}
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                            />
                        </Grid.Col>
                        <Grid.Col span="content">
                            <Button variant="filled" onClick={() => props.fetchInventory(inputValue)}>
                                Load
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
                <div>Username: {props.username}</div>
                <div>User ID: {props.userId}</div>
            </Card>
        </Container>
    );
}

interface SourceInputProps {
    errorMessage?: React.ReactNode;
    inputValue: string;
    setInputValue: (profileUrl: string) => void;
}

function SourceInput(props: SourceInputProps) {
    return (
        <Popover opened={props.errorMessage !== undefined} offset={2} shadow="md" withinPortal>
            <Popover.Target>
                <TextInput
                    label="TBT Profile URL or ID"
                    placeholder="https://www.belltreeforums.com/members/jadetine.17971/"
                    value={props.inputValue}
                    onChange={(event) => props.setInputValue(event.currentTarget.value)}
                />
            </Popover.Target>
            <Popover.Dropdown m={0} p={0} sx={{ maxWidth: "fit-content" }}>
                <Alert color="red">{props.errorMessage}</Alert>
            </Popover.Dropdown>
        </Popover>
    );
}
