import { CookieSetOptions } from "universal-cookie";

const RECENT_USERS_COOKIE = "recent_users";

interface RecentUser {
    userId: string;
    username: string;
    lineup?: string;
}

export function getRecentUsers(cookies: { [x: string]: any }): RecentUser[] {
    return cookies[RECENT_USERS_COOKIE] ?? [];
}

export function updateRecentUsers(
    recentUser: RecentUser,
    cookies: { [x: string]: any },
    setCookie: (name: string, value: any, options?: CookieSetOptions | undefined) => void
) {
    const recentUsers: RecentUser[] = getRecentUsers(cookies);
    // todo indexOf does not appear to work for objects, how do we implement equals()?
    const idx = recentUsers.indexOf(recentUser);
    if (idx >= 0) {
        // remove duplicate if it exists, even if it's already at the front
        // this is a small array so no concerns about performance
        recentUsers.splice(idx, 1);
    }
    recentUsers.unshift(recentUser); // insert latest at the beginning
    recentUsers.splice(5); // only keep up to 5 recent users
    setCookie(RECENT_USERS_COOKIE, recentUsers, {path: "/"});
}
