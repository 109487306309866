import { Card, Center, Container, Grid, Loader, Text } from "@mantine/core";
import { Item } from "./Item";
import { MRT_RowSelectionState } from "mantine-react-table";
import { LineupConfig } from "./LineupConfig";
import { CopyPermalinkButton } from "./CopyPermalinkButton";

interface LineupProps {
    numRows: number;
    setNumRows: (numRows: number) => void;
    numCols: number;
    setNumCols: (numRows: number) => void;
    itemMap: { [id: string]: Item };
    rowSelection: MRT_RowSelectionState;
    setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
    canonicalUrl: string;
    isLoading: boolean;
}

function deselectItem(props: LineupProps, itemId: string) {
    props.setRowSelection((prev) => {
        const next = { ...prev };
        delete next[itemId];
        return next;
    });
}

function getMaxCollectibles(numRows: number, numCols: number): number {
    return numRows * numCols;
}

function getLineupWidth(numCols: number): number {
    return 30 * numCols;
}

export function Lineup(props: LineupProps) {
    function getLineupContent(): JSX.Element {
        if (props.isLoading) {
            // todo center this
            return <Center><Loader color="blue"/></Center>;
        }

        const maxCollectibles = getMaxCollectibles(props.numRows, props.numCols);
        const items = Object.keys(props.rowSelection)
            .filter((itemId) => itemId in props.itemMap && props.rowSelection[itemId]) // skip any false values
            .map((itemId) => props.itemMap[itemId])
            .sort((a, b) => b.date.localeCompare(a.date)); // order by date descending
        const max = Math.min(items.length, maxCollectibles);
        const cols = [];

        for (var slotIdx = 0; slotIdx < max; slotIdx++) {
            const item = items[slotIdx];
            cols.push(
                <Grid.Col key={item.id} mb={2} p={0} span={1} onClick={(e) => deselectItem(props, item.id)}>
                    <img src={item.imageUrl} alt={item.name} />
                </Grid.Col>
            );
        }

        // todo understand why these are modified using withNormalizeCSS and withGlobalStyles
        const placeholder = {
            color: "white",
            width: "28px",
            height: "28px",
            border: "solid black 1px",
            marginBottom: "2px",
        };

        for (; slotIdx < maxCollectibles; slotIdx++) {
            cols.push(
                <Grid.Col key={`placeholder-${slotIdx}`} p={0} span={1}>
                    <div style={placeholder} />
                </Grid.Col>
            );
        }

        return (
            <Container maw={getLineupWidth(props.numCols)} p={0} size="xs">
                <Grid m={0} columns={props.numCols}>{cols}</Grid>
            </Container>
        );
    }

    return (
        <Container size="xl" px="xs">
            <Card shadow="sm" padding="md" m="md" radius="md" withBorder>
                <Card.Section withBorder inheritPadding py="xs">
                    <Grid align="flex-end">
                        <Grid.Col span="auto">
                            <Text weight={500}>Lineup</Text>
                        </Grid.Col>
                        <Grid.Col span="content">
                            <CopyPermalinkButton canonicalUrl={props.canonicalUrl}/>
                        </Grid.Col>
                        <Grid.Col span="content">
                            <LineupConfig
                                numRows={props.numRows}
                                setNumRows={props.setNumRows}
                                numCols={props.numCols}
                                setNumCols={props.setNumCols}
                            />
                        </Grid.Col>
                    </Grid>
                </Card.Section>

                <Card.Section m="md" pb="sm">
                    {getLineupContent()}
                </Card.Section>
            </Card>
        </Container>
    );
}
