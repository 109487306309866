import { MRT_RowSelectionState } from "mantine-react-table";
import { Item } from "./Item";

export interface User {
    userId: string;
    username?: string; // we may not know this when parsing user input
}

export function encodeLineup(
    itemMap: { [itemId: string]: Item },
    rowSelection: MRT_RowSelectionState,
    numRows: number,
    numCols: number
): string {
    // todo extract common code between this and Lineup.tsx
    const numItems = numRows * numCols;
    const lineup = Object.keys(rowSelection)
        .filter((itemId) => itemId in itemMap && rowSelection[itemId]) // skip any false values
        .map((itemId) => itemMap[itemId])
        .sort((a, b) => b.date.localeCompare(a.date)) // order by date descending
        .slice(0, numItems)
        .map((item) => parseInt(item.id))
        .sort((a, b) => a - b); // sort by item id

    console.log(`encoding ${lineup.length} item ids = ${lineup}`);

    // convert item ids to a byte array of var int deltas
    const bytes = [];
    for (let i = 0; i < lineup.length; i++) {
        const itemId = lineup[i];
        let delta = itemId - (i > 0 ? lineup[i - 1] : 0);
        while (delta > 0) {
            // assumes
            // 1. itemId is unique in rowSelection (it had better be because they are keys in an object)
            // 2. there is no itemId 0 (appears to be true)
            const payload = delta & 0x7f; // little endian - LSBs will be written first
            const hasMore = delta > 0x7f;
            if (hasMore) {
                bytes.push(0x80 | payload);
            } else {
                bytes.push(payload);
            }
            delta >>>= 7;
        }
    }

    // item id                  217028
    // binary (7-bit spacing)   1101 0011111 1000100
    // var int binary           11000100 10011111 00001101
    // var int decimal          196      159      13

    // item id                  206796
    // binary (7-bit spacing)   1100 1001111 1001100
    // var int binary           11001100 11001111 00001100
    // var int decimal          204      207      12

    // delta to 217028          217028 - 206796 = 10232
    // binary (7-bit spacing)   1001111 1111000
    // var int binary           11111000 01001111
    // var int decimal          248      79

    console.log(`encoded bytes = ${bytes}`);

    // https://stackoverflow.com/a/42334410
    const encoded = btoa(new Uint8Array(bytes).reduce((data, byte) => data + String.fromCharCode(byte), ""))
        .replaceAll("+", "-")
        .replaceAll("/", "_");
        // we'll use these in url path instead of parameters, so no need to escape =
    console.log(`encoded lineup = ${encoded}`);
    return encoded;
}

export function decodeLineup(encodedLineup: string): string[] {
    console.log(`decoding lineup = ${encodedLineup}`);

    const bytes = atob(encodedLineup.replaceAll("-", "+").replaceAll("_", "/"))
        .split("")
        .map((s) => s.codePointAt(0));

    console.log(`decoded bytes = ${bytes}`);

    const itemIds: number[] = [];
    let i = 0; // index into bytes
    while (i < bytes.length) {
        let delta = 0; // accumulator for current item id delta
        let shift = 0; // how many bits to shift the current byte
        let hasMore; // > 0 if there are more bytes in current item id delta
        do {
            const b = bytes[i]!;
            delta |= (b & 0x7f) << shift;
            // console.log(`decoding varint byte=${b} delta=${delta} i=${i} shift=${shift}`);
            i++;
            shift += 7;
            hasMore = b & 0x80;
        } while (hasMore);

        const prevItemId = itemIds.length > 0 ? itemIds[itemIds.length - 1] : 0;
        itemIds.push(prevItemId + delta);
    }

    console.log(`decoded ${itemIds.length} item ids = ${itemIds}`);

    return itemIds.map(itemId => itemId.toString());
}
