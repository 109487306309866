import { Center, Title } from "@mantine/core";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <Center>
            <div>
                <Title>Oops!</Title>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <em>{isRouteErrorResponse(error) ? error.statusText : "I really don't know what happened."}</em>
                </p>
            </div>
        </Center>
    );
}
