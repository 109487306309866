import { User } from "./user";

// This is a silly function but we're really just verifying the input is non-negative integer.
// We're not using parseInt() because that handles things like negative numbers and hex values.
// If it doesn't look like an integer, we'll return undefined.
function parseUserId(userId: string): User | undefined {
    const regex = /^(\d+)$/;
    const match = userId.trim().match(regex);
    if (!match) {
        return undefined;
    }
    return { userId };
}

function parseUserString(userStr: string): User | undefined {
    const regex = /^(.*)\.(\d+)$/;
    const match = userStr.trim().match(regex);
    if (!match) {
        return undefined;
    }
    const username = match[1];
    const userId = match[2];
    return { userId, username };
}

function parseMemberUrl(profileUrl: string): User | undefined {
    const regex = /^https:\/\/www.belltreeforums.com\/members\/([^.]+)\.(\d+)\/?.*/;
    const match = profileUrl.trim().match(regex);
    if (!match) {
        return undefined;
    }
    const username = match[1];
    const userId = match[2];
    return { userId, username };
}

export function parseInput(input: string): User | undefined {
    return parseUserId(input) ?? parseUserString(input) ?? parseMemberUrl(input);
}
