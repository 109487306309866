import { Flex, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { USER_GUIDE_URL } from "./constants";

// return the error message for the input popover
export function InvalidInputError() {
    return (
        <>
            <Flex align="center">
                <IconAlertCircle color="red" size="1em" />
                <Text ml={4}>Invalid profile URL</Text>
            </Flex>
            <div>A profile URL looks like https://www.belltreeforums.com/members/jadetine.17971/</div>
            <div>
                For more help, see the <a href={USER_GUIDE_URL}>user guide</a>.
            </div>
        </>
    )
}