import { useMemo } from "react";
import { Item } from "./Item";
import {
    MantineReactTable,
    useMantineReactTable,
    type MRT_ColumnDef,
    MRT_RowSelectionState,
} from "mantine-react-table";
import { Card, Center, Container, Flex, MediaQuery } from "@mantine/core";
import { USER_GUIDE_URL } from "./constants";

export interface InventoryTableProps {
    items: Item[];
    itemMap: { [id: string]: Item };
    rowSelection: MRT_RowSelectionState;
    setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
    isLoading: boolean;
}

export function InventoryTable(props: InventoryTableProps) {
    // props.items.forEach((item) => console.log(item.name));

    const columns = useMemo<MRT_ColumnDef<Item>[]>(
        () => [
            {
                accessorFn: (item) => item.imageUrl,
                header: "Icon",
                Cell: ({ cell }) => (
                    <img src={cell.getValue<string>()} alt={`${cell.row.original.name} #${cell.row.original.id}`} />
                ),
                enableSorting: false,
                enableColumnFilter: false,
                size: 50,
            },
            {
                accessorKey: "name",
                header: "Collectible",
            },
            {
                accessorKey: "date",
                header: "Date",
            },
            {
                accessorKey: "sentBy",
                header: "Buyer",
            },
        ],
        []
    );

    const table = useMantineReactTable<Item>({
        columns,
        data: props.items,
        enableColumnOrdering: true,
        enableColumnResizing: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enablePagination: false,
        enableRowSelection: true,
        enableRowVirtualization: true, // power users have > 50 items so row virtualization is recommended
        enableStickyHeader: true,
        getRowId: (item) => item.id,
        onRowSelectionChange: props.setRowSelection,
        renderEmptyRowsFallback: (table) => (
            <>
                <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                    <Center sx={{ color: "gray", fontStyle: "italic" }}>
                        <div>
                            <div>No collectibles found.</div>
                            <div>Are the collectibles hidden?</div>
                            <div>
                                For more help, see the <a href={USER_GUIDE_URL}>user guide</a>.
                            </div>
                        </div>
                    </Center>
                </MediaQuery>
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                    <Flex direction="row" align="center" sx={{ color: "gray", fontStyle: "italic" }}>
                        <div>
                            <div>No collectibles found.</div>
                            <div>Are the collectibles hidden?</div>
                            <div>
                                For more help, see the <a href={USER_GUIDE_URL}>user guide</a>.
                            </div>
                        </div>
                    </Flex>
                </MediaQuery>
            </>
        ),
        mantineTableContainerProps: {
            sx: { maxHeight: "55vh" },
        },
        initialState: {
            density: "xs",
        },
        state: {
            rowSelection: props.rowSelection,
            isLoading: props.isLoading,
        },
    });

    return (
        <Container size="xl" px="xs">
            <Card shadow="sm" padding="md" m="md" radius="md" withBorder>
                <MantineReactTable table={table} />
            </Card>
        </Container>
    );
}
