import { Button, Flex, Modal, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DEFAULT_NUM_COLS, DEFAULT_NUM_ROWS, MAX_COLS, MAX_ROWS } from "./constants";
import { useEffect, useState } from "react";

interface LineupConfigProps {
    numRows: number;
    setNumRows: (numRows: number) => void;
    numCols: number;
    setNumCols: (numRows: number) => void;
}

export function LineupConfig(props: LineupConfigProps) {
    const [opened, { open, close }] = useDisclosure(false);
    const [numRowsInput, setNumRowsInput] = useState<string>();
    const [numColsInput, setNumColsInput] = useState<string>();
    const [rowsError, setRowsError] = useState<string>("");
    const [colsError, setColsError] = useState<string>("");

    useEffect(
        () => {
            setNumRowsInput(props.numRows.toString());
            setNumColsInput(props.numCols.toString());
        },
        [props.numRows, props.numCols]
    );

    function setNumRows(value: string) {
        setNumRowsInput(value);

        try {
            const rows = parseInt(value);
            if (1 <= rows && rows <= MAX_ROWS) {
                props.setNumRows(rows);
                setRowsError(""); // clear error message
                return;
            }
        } catch(e) {
            // swallow
        }
        setRowsError(`Rows must be a number between 1 and ${MAX_ROWS}`);
    }

    function setNumCols(value: string) {
        setNumColsInput(value);

        try {
            const cols = parseInt(value);
            if (1 <= cols && cols <= MAX_COLS) {
                props.setNumCols(cols);
                setColsError(""); // clear error message
                return;
            }
        } catch(e) {
            // swallow
        }
        setColsError(`Columns must be a number between 1 and ${MAX_COLS}`);
    }

    function resetToDefaults() {
        setNumRows(DEFAULT_NUM_ROWS.toString());
        setNumCols(DEFAULT_NUM_COLS.toString());
    }

    function makeItHuge() {
        setNumRows(MAX_ROWS.toString());
        setNumCols(MAX_COLS.toString());
    }

    // sometimes TBT does this
    function twoBySix() {
        setNumRows("2");
        setNumCols("6");
    }

    function phoneSized() {
        setNumRows(MAX_ROWS.toString());
        setNumCols("8");
    }

    return <>
        <Modal opened={opened} onClose={close} title="Lineup Options" centered>
            <TextInput
                label="Rows"
                value={numRowsInput}
                onChange={(event) => setNumRows(event.currentTarget.value)}
                error={rowsError}
            />

            <TextInput
                label="Columns"
                value={numColsInput}
                onChange={(event) => setNumCols(event.currentTarget.value)}
                error={colsError}
            />

            <Flex justify="flex-end" mt="md" gap="md" wrap="wrap">
                <Button onClick={resetToDefaults}>Default 2x5</Button>
                <Button onClick={twoBySix}>2x6</Button>
                <Button onClick={makeItHuge}>Make it huge!</Button>
                <Button onClick={phoneSized}>That's too big, I'm on phone 📱</Button>
            </Flex>
        </Modal>
        <Button size="xs" onClick={open}>Options</Button>
    </>
}