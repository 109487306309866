import "./App.css";
import { MantineProvider } from "@mantine/core";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CollectiblesTool } from "./CollectiblesTool";
import ErrorPage from "./ErrorPage";

// Layout is on search parameters instead of dynamic path parameters
// because I think using the default layout will be most common.
// Thus we can usually omit it completely from the urls.
const router = createBrowserRouter([
    {
        path: "/",
        element: <CollectiblesTool fromCookie/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/inventory/:user/",
        element: <CollectiblesTool />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/inventory/:user/:lineup/",
        element: <CollectiblesTool />,
        errorElement: <ErrorPage />,
    },
]);

function App() {
    return (
        <MantineProvider withNormalizeCSS withGlobalStyles>
            <RouterProvider router={router} />
        </MantineProvider>
    );
}

export default App;
